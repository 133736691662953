import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { browserTracingIntegration } from '@sentry/vue';

import AuthError, { AuthErrorType } from '@/helpers/AuthError';

export enum CLIENT_ERROR_MESSAGES {
  REQUEST_ABORTED = 'Request aborted',
  NETWORK_ERROR = 'Network Error',
  NAVIGATION_CANCELLED = 'Navigation cancelled',
}
export enum CLIENT_ERROR_NAMES {
  NAVIGATION_DUPLICATED = 'NavigationDuplicated',
}
export const CHUNK_LOAD_ERROR_MESSAGE_REGEX = /loading ?[^]* chunk [^]* failed./i;

const CLIENT_ERROR_TRIGGERS = {
  messages: Object.values(CLIENT_ERROR_MESSAGES) as string[],
  names: Object.values(CLIENT_ERROR_NAMES) as string[],
  regexes: [CHUNK_LOAD_ERROR_MESSAGE_REGEX],
};

const INFO_LEVEL_ERRORS = [
  AuthErrorType.NOT_AUTHORIZED_ERROR,
  AuthErrorType.EMAIL_EXISTS_ERROR,
  AuthErrorType.WHITESPACE_IN_USERNAME_ERROR,
  AuthErrorType.CODE_EXPIRED_ERROR,
  AuthErrorType.ATTEMPTS_EXCEEDED_ERROR,
];

export const isInfoLevelError = (error) => {
  if (error instanceof AuthError && INFO_LEVEL_ERRORS.includes(error?.type)) {
    return true;
  }
  if (error instanceof Error) {
    if (CLIENT_ERROR_TRIGGERS.messages.some((msg) => error?.message?.includes(msg))) return true;
    if (CLIENT_ERROR_TRIGGERS.names.includes(error?.name)) return true;
    if (CLIENT_ERROR_TRIGGERS.regexes.some((regex) => regex.test(error?.message))) return true;
  }
  return false;
};

const allowUrls = [/homegate\.ch/, /\.homegate\.ch/, /immoscout24\.ch/, /\.immoscout24\.ch/];

const ignoredEventKeywords = ['cookielaw', 'adnxs', 'user is blocked'];
function isIgnoredErrorEvent(event: Sentry.Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  const isIgnoredEvent = frames?.some((frame) => {
    const stringifiedFrame = JSON.stringify(frame);
    return ignoredEventKeywords.some((ignoredEventKeyword) => stringifiedFrame.includes(ignoredEventKeyword));
  });

  return !!isIgnoredEvent;
}

export const sentryInit = () => {
  const environment = process.env.VUE_APP_ENVIRONMENT;

  if (environment === 'prod' || environment === 'test' || environment === 'dev') {
    Sentry.init({
      allowUrls,
      Vue: Vue,
      dsn: 'https://aa79a23396f44fff88c5cebc9876ac59@o186622.ingest.sentry.io/5312700',
      environment: environment,
      release: process.env.VUE_APP_SENTRY_RELEASE_VERSION,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: 0.01,
      tracingOptions: {
        trackComponents: true,
      },
      beforeSend: function (event, hint) {
        if (isIgnoredErrorEvent(event)) {
          return null;
        }

        const exception = hint?.originalException;

        if (isInfoLevelError(exception)) {
          event.level = 'info';
        }

        return event;
      },
    });

    Sentry.getCurrentScope().setTag('bundle', 'client');
  }
};
