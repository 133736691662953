import { isPlatformIs24 } from '@/helpers/PlatformDetection';
import axios from 'axios';
import { getAuth0Client } from '@/auth/auth0Client';
import { getIS24AccessTokenCookiesValue, getIS24PersonIdCookiesValue } from './CookiesHelper';
import { getAccessToken as getIs24AccessToken } from '@/router/is24/guardHelper';
import { shouldWeUseAuth0 } from '@/helpers/Is24Auth0ReleaseHelper';

export const getAccessToken = async (): Promise<string | undefined> => {
  if (isPlatformIs24() && !shouldWeUseAuth0()) {
    return getIS24AccessTokenCookiesValue() || (await getIs24AccessToken());
  }

  try {
    const authClient = getAuth0Client();
    if (!(await authClient.isAuthenticated())) {
      return undefined;
    }
    return await authClient.getTokenSilently();
  } catch (error) {
    return undefined;
  }
};

export async function getUserId(token: string): Promise<string | undefined> {
  try {
    if (isPlatformIs24() && !shouldWeUseAuth0()) {
      const userId = getIS24PersonIdCookiesValue();

      if (userId) {
        return userId;
      }

      const response = await axios.get(`${build.config.IS24_REST_API_URL}/en/users/userinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response?.data?.personId || '';
    } else {
      const authClient = getAuth0Client();
      const claims = await authClient.getIdTokenClaims();
      return claims?.['cognito:username'] || '';
    }
  } catch (error) {
    console.error('Error in getUserId:', error);
    return undefined;
  }
}
