import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { ApiResponse } from '@/helpers/Is24Auth0ReleaseHelper';
import { ActionContext } from 'vuex';

export interface IState {
  is24Auth0ReleaseApiValue: number;
}

const state: IState = {
  is24Auth0ReleaseApiValue: 0,
};

const getters = {
  is24Auth0ReleaseApiValue: ({ is24Auth0ReleaseApiValue }: IState): Number => is24Auth0ReleaseApiValue,
};

const mutations = {
  setIs24Auth0ReleaseApiValue(state: IState, value: number): void {
    state.is24Auth0ReleaseApiValue = value;
  },
};

const actions = {
  fetchIs24Auth0ReleaseApiValue: async ({ commit }: ActionContext<IState, IState>): Promise<void> => {
    try {
      const response = await axios.get<ApiResponse>(`${process.env.VUE_APP_ACCOUNT_MGMT_API_URL}/login-rate`);

      commit('setIs24Auth0ReleaseApiValue', response.data.ratePercent);
    } catch (error) {
      Sentry.captureException(error);
    }
  },
};

const Is24Auth0ReleaseModule = { namespaced: true, state, getters, mutations, actions };
export default Is24Auth0ReleaseModule;
